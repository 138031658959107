<template>
     <v-row  justify="center" 
        align="center">
        <v-row class="text-center mb-3" justify="center" 
            align="center">
            <v-col cols="12" class="mt-10">
            <v-icon 
            class="icon black--text"
            size="70"
            dense>fas fa-map-marker-alt</v-icon>
        </v-col>
            <v-col cols="12" >
                <h1 class="display-2 font-weight-bold mb-3">
                    Notre localisation
                </h1>
            </v-col>
            <v-col cols="12">
                <iframe 
                    width="100%" 
                    height="400" 
                    id="gmap_canvas" 
                    src="https://maps.google.com/maps?q=Efam%20ecole%20de%20formation,%20Rue%20des%20Fr%C3%A8res%20Bouadou,%20Bir%20Mourad%20Ra%C3%AFs&t=&z=13&ie=UTF8&iwloc=&output=embed" 
                    frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                </iframe>
            </v-col>
        </v-row>
    </v-row>
</template>
<script>
export default {
    name: 'Contact',
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    }
}
</script>